@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: 'Prompt'
}

code {
  font-family: 'Prompt'
}
